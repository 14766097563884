import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import {Container, Row, Col} from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"


const StyledFooter = styled.footer`
    padding: 50px 0 65px;
`

// const List = styled.ul`
//     margin: 0;
//     padding: 0;
// `

const Copyright = styled.p`
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
`

const Footer = () => {

    const data = useStaticQuery(graphql`
      query FooterQuery {
        file(name: {eq: "footer"}) {
          id
          childMdx {            
            body
            frontmatter {
              hero {
                childImageSharp {
                  fluid(cropFocus: ATTENTION, fit: CONTAIN) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `)
    const bio = data.file.childMdx.body
    const image = data.file.childMdx.frontmatter.hero.childImageSharp.fluid
    return (
        <StyledFooter>
          <Container>
            <Row className="align-items-center">
              <Col>
                <Img fluid={image} className="rounded-circle" imgStyle={{ objectFit: 'contain' }}/>
              </Col>
              <Col lg="8" md="10" className="mx-auto">                
                <MDXRenderer>{bio}</MDXRenderer>                
              </Col>              
            </Row>  
            <Row>
              <Col>
                <Copyright className="text-muted">
                  © {new Date().getFullYear()}, all rights reserved.
                </Copyright>              
              </Col>
            </Row>        
          </Container>          
        </StyledFooter>
    )
}

export default Footer