import React from "react"
import { Col, Container, Row } from "reactstrap";

const MastHead = ({overlay, title, subtitle, meta, image}) => {
    return (
        <header className="masthead" style={{backgroundImage: "url('" + image + "')"}}>
        {overlay && 
            <div className="overlay"></div>
        }        
        <Container>
        <Row>
            <Col lg="8" md="10" className="mx-auto">
            <div className="site-heading">
                <h1>{title}</h1>
                <span className="subheading">{subtitle}</span>
                {meta && 
                    <span className="meta">{meta}</span>
                }                
            </div>
            </Col>
        </Row>
        </Container>
    </header>
    )
}

export default MastHead;