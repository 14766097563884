import React, { useState } from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';


const Header = (props) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
        <Navbar id="mainNav" expand="lg" fixed="top">
          <Container>
            <NavbarBrand href="/">Sameer Segal</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>                
                <NavItem>
                  <NavLink href="/thinking">Thinking</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/tech">Tech</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/triathlon">Triathlon</NavLink>
                </NavItem>                
                <NavItem>
                  <NavLink href="/dadventures">Dadventures</NavLink>
                </NavItem>                                
              </Nav>              
            </Collapse>
          </Container>
        </Navbar>
      )
}

export default Header
