/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {ThemeProvider} from 'styled-components'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faPagelines } from '@fortawesome/free-brands-svg-icons'
import { faSeedling, faTree  } from '@fortawesome/free-solid-svg-icons'

import Header from "./header"
import Footer from "./footer"
import theme from '../assets/stylesheets/theme'
import "../assets/stylesheets/layout.scss" 

library.add(fab, faSeedling, faPagelines, faTree)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main style={{"background":"#f4f4f4"}}>{children}</main>
      <Footer />        
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
